import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  output,
} from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';

interface IWindow extends Window {
  webkitSpeechRecognition: any;
}

interface SpeechRecognitionEvent {
  results: SpeechRecognitionResultList;
}

interface SpeechRecognitionResultList {
  [index: number]: SpeechRecognitionResult;
  length: number;
}

interface SpeechRecognitionResult {
  isFinal: boolean;
  length: number;
  [index: number]: SpeechRecognitionAlternative;
}

interface SpeechRecognitionAlternative {
  transcript: string;
  confidence: number;
}

@Component({
  selector: 'etoh-speech-to-text',
  standalone: true,
  imports: [CommonModule, NzButtonModule, NzIconModule, FlexLayoutModule],
  template: `
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
      <span fxFlex></span>

      <div>
        @if (isListening) { Recording... } @else { Do it with the voice ({{
          recognition.lang
        }}) }
      </div>
      <div>
        @if (isListening) {
        <button
          type="button"
          class="loading"
          nz-button
          nzType="default"
          nzShape="circle"
          (click)="stopListening()"
        >
          <span nz-icon nzType="pause"></span>
        </button>
        } @else {
        <button
          type="button"
          (click)="startListening()"
          nz-button
          nzType="default"
          nzShape="circle"
        >
          <span nz-icon nzType="audio"></span>
        </button>
        }
      </div>
    </div>
  `,
  styles: `
.loading {
  outline-width: 1px;
  outline-offset: 0;
  outline-color: rgba(0, 130, 206, 0.75);
  outline-style: solid;
  animation: animateOutline 3s ease infinite;

  @keyframes animateOutline {
  0% {
    outline-width: 1px;
    outline-offset: 0;
    outline-color: rgba(0, 130, 206, 0);
  }

  10% {
    outline-color: rgba(0, 130, 206, 0.75);
  }

  /* The animation finishes at 50% */
  50% {
    outline-width: 7px;
    outline-offset: 4px;
    outline-color: rgba(0, 130, 206, 0);
  }

  100% {
    outline-width: 7px;
    outline-offset: 4px;
    outline-color: rgba(102, 102, 102, 0);
  }
}
}
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpeechToTextComponent {
  recognition: IWindow['webkitSpeechRecognition'];
  isListening = false;
  transcript: string | null = null;

  change = output<SafeHtml>();

  cd = inject(ChangeDetectorRef);
  sanitized = inject(DomSanitizer);

  constructor() {
    const { webkitSpeechRecognition }: IWindow = window as any as IWindow;
    this.recognition = new webkitSpeechRecognition();
    this.recognition.continuous = true;
    this.recognition.lang = window.navigator.language; // Définissez la langue souhaitée
    this.recognition.interimResults = true;

    this.recognition.onresult = (event: SpeechRecognitionEvent) => {
      console.log('event', event);
      const results = event.results;
      const resultLength = results.length - 1;

      let transcript = '';
      for (let i = 0; i <= resultLength; i++) {
        const transcriptFragment = results[i][0];

        console.log('transcriptFragment', transcriptFragment);
        let transcriptResult = transcriptFragment.transcript;
        if (results[i].isFinal) {
          transcriptResult = ['<b>', transcriptResult, '</b>'].join('');
        }

        transcript = transcript + transcriptResult;
      }
      this.transcript = transcript; //this.sanitized.bypassSecurityTrustHtml(transcript);
      this.change.emit(this.transcript);
      console.log('transcript', transcript);
      this.cd.detectChanges();
    };

    this.recognition.onerror = (event: any) => {
      console.error(event.error);
    };
  }

  startListening() {
    this.isListening = true;
    try {
      this.recognition.start();
    } catch (error) {
      console.error(error);
    }
  }

  stopListening() {
    console.log('stopListening');
    this.isListening = false;
    try {
      this.recognition.stop();
      this.recognition.abort();
    } catch (error) {
      console.error(error);
    }
  }
}
