import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  Type,
} from '@angular/core';
import {
  FieldType,
  FieldTypeConfig,
  FormlyFieldConfig,
  FormlyFieldProps,
} from '@ngx-formly/core';
import { FormlyFieldSelectProps } from '@ngx-formly/core/select';
import { FormlyFieldSelect } from '@ngx-formly/ng-zorro-antd/select';
import { BehaviorSubject, Subject, Subscription, take } from 'rxjs';

import { SelectOptions } from '@etoh/database/core';
import { FormsService } from '../../../features/shared/forms/forms.service';
import {
  FormSelectFooterType,
  FormSelectTooltip,
} from './select-type.interface';

interface SelectProps extends FormlyFieldProps, FormlyFieldSelectProps {
  multiple?: boolean;
  allowClear?: boolean;

  tooltip?: FormSelectTooltip<any>;
  footer?: FormSelectFooterType;
  options: SelectOptions<any>[];
  customSorting?: (options: any[], searchValue: string) => any[];
}

export interface FormlySelectFieldConfig
  extends FormlyFieldConfig<SelectProps> {
  type: 'select' | Type<FormlyFieldSelect>;
}

@Component({
  selector: 'etoh-field-nz-tags',
  template: `
    <nz-select
      [class.ng-dirty]="showError"
      [nzPlaceHolder]="props.placeholder ?? ''"
      [formControl]="formControl"
      [nzMode]="props.multiple ? 'multiple' : 'default'"
      [formlyAttributes]="field"
      nzShowSearch
      [nzAllowClear]="props.allowClear ?? false"
      [nzLoading]="isLoading"
      [nzDisabled]="props.disabled"
      (nzOnSearch)="onSearch($event)"
      (ngModelChange)="props.change && props.change(field, $event)"
      [nzDropdownRender]="props.footer?.component ? renderTemplate : null"
      [nzCustomTemplate]="augmentedTemplate"
    >
      @let options = props.options |
      formlySelectOptions2:field:props.customSorting:searchValueBehavior.value;
      @if ((options | async)?.[0]?.group) { @for (item of options | async; track
      $index; let last = $last; let count = $count ) { @if (last && isLoading &&
      setFalseLoading()) {}

      <nz-option-group [nzLabel]="item.label">
        @for (child of item.group; track child.value) {
        <nz-option
          nzCustomContent
          [nzValue]="child.value"
          [nzDisabled]="child.disabled"
          [nzLabel]="child.label"
        >
          {{ child.label }}

          @if(child.subLabel) {
          <small>{{ child.subLabel }}</small>
          }
        </nz-option>
        }
      </nz-option-group>
      } } @else { @for (item of options | async; track item.value; let last =
      $last; let count = $count ) { @if (last && isLoading && setFalseLoading())
      { }
      <nz-option
        nzCustomContent
        [nzValue]="item.value"
        [nzDisabled]="item.disabled"
        [nzLabel]="item.label"
      >
        {{ item.label }}
        @if(item.subLabel) {
        <small>{{ item.subLabel }}</small>
        }
      </nz-option>
      } }
    </nz-select>
    @if (((options | async)?.length === 0) && setFalseLoading()) { }

    <ng-template #renderTemplate>
      @if (props.footer) {
      <div class="container mat-padding-1">
        <button
          type="button"
          nz-button
          nzType="dashed"
          nzBlock
          (click)="addForm(props.footer.component)"
        >
          {{ props.footer.text }}
        </button>
      </div>
      }
    </ng-template>

    <ng-template #augmentedTemplate let-selected>
      @if (props.tooltip?.getTitle) { @if (props.tooltip?.getTitle) {
      <div
        class="ant-select-selection-item-content"
        nz-tooltip
        [nzTooltipTitle]="titleTemplate"
      >
        <span nz-icon nzType="info-circle" nzTheme="fill"></span>
        {{ selected.nzLabel }}
      </div>
      }
      <ng-template #titleTemplate>
        <span>{{ props.tooltip?.getTitle(selected.nzValue) | async }}</span>
        @if (props.tooltip?.editComponent) {
        <span
          class="pointer"
          style="margin-left: 4px;"
          (click)="editPageForm(selected.nzValue)"
          nz-icon
          nzType="edit"
        ></span>
        }
      </ng-template>
      } @if (!props.tooltip?.getTitle) {
      <div class="ant-select-selection-item-content">
        {{ selected.nzLabel }}
      </div>
      }
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyFieldSelectType
  extends FieldType<FieldTypeConfig<SelectProps>>
  implements OnInit, OnDestroy
{
  isLoading = true;
  rendered = new Subject<any[]>();
  renderedSubscription: Subscription;

  searchValueBehavior = new BehaviorSubject<string>('');

  constructor(private formsService: FormsService) {
    super();
  }

  ngOnInit() {
    this.renderedSubscription = this.rendered.pipe(take(1)).subscribe(() => {
      this.isLoading = false;
    });
  }

  onSearch(searchValue: string) {
    // avoid rerendering if not necessary
    if (this.props.customSorting) {
      this.searchValueBehavior.next(searchValue);
    }
  }

  setFalseLoading() {
    console.log('setFalseLoading');
    this.isLoading = false;
    return true;
  }

  ngOnDestroy() {
    this.renderedSubscription?.unsubscribe();
  }

  public editPageForm(id: number) {
    console.log('id', id, this.props.tooltip?.editComponent);
    this.formsService
      .openModalPageForm(this.props.tooltip?.editComponent, {
        id,
      })
      .afterClose.subscribe((res) => {
        console.log('res', res);
      });
  }

  addForm(component: any) {
    this.formsService
      .openModalPageForm(component, {
        model: this.props.footer?.getInitialState?.() ?? {},
      })
      .afterClose.subscribe((res) => {
        if (res) {
          if (this.props?.multiple) {
            const array = this.formControl.value || [];
            this.formControl.setValue([...array, res]);
          } else {
            this.formControl.setValue(res);
          }
        }
      });
  }
}
