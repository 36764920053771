import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Items } from '@etoh/database/core';
import { NZ_DRAWER_DATA, NzDrawerRef } from 'ng-zorro-antd/drawer';
import { ItemWorkflowAddComponent } from './ai-workflow/item-workflow-add.component';
import { GeneratedItem } from './ai-workflow/item-workflow-add.interface';

@Component({
  selector: 'etoh-ai-workflow-drawer',
  standalone: true,
  imports: [CommonModule, ItemWorkflowAddComponent],
  template: `
    <etoh-item-workflow-add
      [couldBeBulk]="!!nzData.couldBeBulk"
      (generatedItems)="closeDrawerWithItems($event)"
    ></etoh-item-workflow-add>
  `,
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiWorkflowDrawerComponent {
  nzData: { couldBeBulk?: boolean } = inject(NZ_DRAWER_DATA);

  constructor(private drawerRef: NzDrawerRef<Partial<Items> | undefined>) {}

  async closeDrawerWithItems(partialItems: GeneratedItem[] = []) {
    console.log('partialItems', partialItems);
    this.drawerRef.close(partialItems);
  }
}
