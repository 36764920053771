import { Injectable } from '@angular/core';
import { BuySpecs, SelectOptions } from '@etoh/database/core';

import { map, Observable, shareReplay } from 'rxjs';
import { getSelectOptions } from '../../dynamic-form/utilities';
import { StoreService } from '../store.service';

@Injectable({
  providedIn: 'root',
})
export class BuySpecsService {
  constructor(private storeService: StoreService) {}

  getBuySpecsForOptions(buySpecs: BuySpecs[]): SelectOptions[] {
    return getSelectOptions(buySpecs, this.getBuySpecsLabel, 'id');
  }

  getBuySpecsForOptions$(): Observable<SelectOptions[]> {
    return this.storeService.store.buySpecs.data$.pipe(
      map((buySpecs) => this.getBuySpecsForOptions(buySpecs))
    );
  }

  getBuySpecsLabel(buySpec: BuySpecs): string {
    return `Buy spec n°${buySpec.id}`;
  }

  getTooltipFromId(id: number): Observable<string> {
    return this.storeService
      .getObjectFromStoreById<BuySpecs>('buySpecs', id)
      .pipe(map(this.getBuySpecsLabel), shareReplay(1));
  }
}
