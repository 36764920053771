import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import {
  GetUserByIdGQL,
  Maybe,
  UpdateUserByPkGQL,
  Users,
} from '@etoh/database/angular';
import { RoleDatabase } from '@etoh/database/core';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  from,
  lastValueFrom,
  map,
  merge,
  Observable,
  of,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { CacheService } from './cache/cache.service';

type UserPartialJsonField = keyof Pick<
  Users,
  'savedColumns' | 'savedFormFields'
>;

interface SavedFormField {
  commodityType?: string;
  uom?: string;
  currency?: string;

  pdfSamplePackingListLanguage?: string;
  pdfSamplePackingListTemplate?: string;

  pdfAgreementLanguage?: string;
  pdfAgreementTemplate?: string;

  pdfSampleLabelTemplate?: string;

  agreementDiffDateHours?: number;

  dashboardSummaryCommodityType?: string;
  dashboardSnapshotCommodityType?: string;
  dashboardQuickviewCommodityType?: string;
}

type SavedFormFieldKey = keyof SavedFormField;

@Injectable({
  providedIn: 'root',
})
export class UserService {
  userId$ = new BehaviorSubject<string | undefined>(undefined);
  userOfficeId$ = new BehaviorSubject<Maybe<number> | undefined>(undefined);

  // null: logged in firebase but not in db
  // undefined: not logged in firebase
  user$: Observable<Users | null | undefined>;
  role$ = new BehaviorSubject<RoleDatabase | undefined>(undefined);
  userBehaviourSubject$ = new BehaviorSubject<Users | null>(null);
  updateUser$ = new BehaviorSubject<any>(null);

  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private getUserByIdGQL: GetUserByIdGQL,
    private updateUserByPk: UpdateUserByPkGQL,
    private cacheService: CacheService
  ) {
    this.user$ = combineLatest([this.afAuth.authState, this.updateUser$]).pipe(
      switchMap(([firebaseUser]) => {
        if (!firebaseUser) {
          return of(undefined);
        }

        const cacheUser$: Observable<Users> = from(
          this.cacheService.get<Users>(`user-${firebaseUser.uid}`)
        ).pipe(filter((d) => d !== undefined)) as any;

        return merge(cacheUser$, this.getUserByUid(firebaseUser.uid));
      }),
      tap((user) => {
        this.userId$.next(user?.id);
        this.userBehaviourSubject$.next(user || null);
        this.userOfficeId$.next(user?.officeId);
        this.role$.next(user?.role as any);
      }),
      shareReplay(1)
    );

    this.user$.subscribe();
  }

  private getUserByUid(userId: string): Observable<Users> {
    return this.getUserByIdGQL
      .fetch(
        { id: userId },
        {
          context: {
            disableGlobalError: true,
          },
        }
      )
      .pipe(
        map((res) => res.data.users_by_pk as any as Users),
        tap((user) => {
          this.cacheService.set(`user-${userId}`, user);
        })
      );
  }

  public updateUserById(id: string, partialUser: Partial<Users>) {
    return this.updateUserByPk
      .mutate({
        id,
        partialUser,
      })
      .pipe(
        // map((res) => res?.data?.update_users_by_pk as any as Users),
        tap((res) => {
          this.updateUser$.next(null);
        })
      );
  }

  public async updateJsonbField(
    fieldId: UserPartialJsonField,
    subFieldKey: SavedFormFieldKey | string,
    value: any
  ) {
    const user = await lastValueFrom(this.user$.pipe(take(1)));

    if (!user) {
      return;
    }

    const field = user[fieldId] || {};
    field[subFieldKey] = value;

    this.updateUserById(user.id, {
      [fieldId]: field,
    }).subscribe();
  }

  public getJsonField(
    fieldId: UserPartialJsonField,
    subFieldKey: SavedFormFieldKey | string
  ) {
    const user = this.userBehaviourSubject$.value;
    if (!user) {
      return null;
    }

    return user[fieldId]?.[subFieldKey];
  }

  public getOfficeId(): Observable<number | any> {
    return this.user$.pipe(
      map((user) => {
        return user?.officeId;
      })
    );
  }

  public cleverRedirect() {
    return this.afAuth.user
      .pipe(
        take(1),
        tap((user) => {
          if (!user) {
            this.router.navigateByUrl('/auth/login');
          }

          if (!user?.emailVerified) {
            this.router.navigateByUrl('/auth/verification');
          }
        }),
        switchMap((user) => {
          return this.user$;
        }),
        take(1),
        tap((user) => {
          if (!user) {
            this.router.navigateByUrl('/auth/verification');
          } else {
            this.redirectUser(user);
          }
        })
      )
      .subscribe();
  }

  public redirectUser(user: Users) {
    if (user.role === 'user') {
      this.router.navigateByUrl('/features/dashboard');
    } else if (user.role === 'guest') {
      this.router.navigateByUrl('/features/items');
    }
  }
}
