import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Type,
} from '@angular/core';
import { SelectOptions } from '@etoh/database/core';
import {
  FieldType,
  FieldTypeConfig,
  FormlyFieldConfig,
  FormlyFieldProps,
} from '@ngx-formly/core';
import { FormlyFieldInput } from '@ngx-formly/ng-zorro-antd/input';
import { combineLatest, map, Observable, of, startWith } from 'rxjs';

interface InputProps extends FormlyFieldProps {
  prefix?: string;
  suffix?: string;

  options?: SelectOptions[];
}

export interface FormlyInputFieldConfig extends FormlyFieldConfig<InputProps> {
  type: 'input' | Type<FormlyFieldInput>;
}

// for address : https://github.com/trellisorg/platform/blob/master/packages/google-places-autocomplete/README.md

@Component({
  selector: 'formly-field-input-group',
  template: `
    <nz-input-group
      [nzAddOnBefore]="props.prefix"
      [nzAddOnAfter]="props.suffix"
    >
      @if (!(props.type === 'number' || props.type === 'address')) { @if
      (!props.options) {
      <input
        nz-input
        type="text"
        (ngModelChange)="props.change && props.change(field, $event)"
        [formControl]="formControl"
        [type]="props.type || 'text'"
        [formlyAttributes]="field"
      />
      } @else {
      <input
        [nzAutocomplete]="auto"
        nz-input
        [formControl]="formControl"
        [type]="props.type || 'text'"
        [formlyAttributes]="field"
        (ngModelChange)="props.change && props.change(field, $event)"
      />
      <nz-autocomplete #auto>
        @for(option of filteredOptions$ | formlySelectOptions2: field | async;
        track option.value) {
        <nz-auto-option [nzValue]="option.value">{{
          option.label
        }}</nz-auto-option>
        }
      </nz-autocomplete>
      } } @if (props.type === 'address') { @if (!props.options) {
      <input
        nz-input
        (keydown.enter)="$event.preventDefault()"
        placesAutocomplete
        (placeChanged)="props.change && props.change(field, $event)"
        type="text"
        [formControl]="formControl"
        [type]="props.type || 'text'"
        [formlyAttributes]="field"
      />
      } @else {
      <input
        [nzAutocomplete]="auto"
        nz-input
        [formControl]="formControl"
        [type]="props.type || 'text'"
        [formlyAttributes]="field"
        (ngModelChange)="props.change && props.change(field, $event)"
      />
      <nz-autocomplete #auto>
        @for(option of filteredOptions$ | formlySelectOptions2: field | async;
        track option.value) {
        <nz-auto-option [nzValue]="option.value">{{
          option.label
        }}</nz-auto-option>
        }
      </nz-autocomplete>
      } } @if (props.type === 'number') {
      <nz-input-number
        [nzMin]="props.min ?? 0"
        [nzMax]="props.max ?? 10000000000"
        [formControl]="formControl"
        [formlyAttributes]="field"
        (ngModelChange)="
          castToNumber(); props.change && props.change(field, $event)
        "
      ></nz-input-number>
      }
    </nz-input-group>

    @if (props.type === 'number') {
    <div style="opacity: 0.7">
      {{ formControl.value | formatNumber }}
    </div>
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyFieldInputGroup
  extends FieldType<FieldTypeConfig<InputProps>>
  implements OnInit
{
  filteredOptions$: Observable<SelectOptions[]>;

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.filteredOptions$ = combineLatest([
      this.formControl.valueChanges.pipe(startWith(this.formControl.value)),
      this.getObservableOptions(this.props.options as any),
    ]).pipe(
      map(([value, options]) => {
        if (!options) {
          return [];
        }

        if (!(value as any)?.length) {
          return options;
        }

        return this.getFilteredOptions(value as any, options);
      })
    );
  }

  private getObservableOptions(
    options: Observable<SelectOptions[]> | SelectOptions[]
  ): Observable<SelectOptions[]> {
    if (options instanceof Observable) {
      return options;
    }

    return of(options);
  }

  getFilteredOptions(value: string, options: SelectOptions[]): SelectOptions[] {
    if (!options) {
      return [];
    }

    return options.filter((option) =>
      (option as SelectOptions).label
        .toLocaleLowerCase()
        .includes(value.toLocaleLowerCase())
    );
  }

  public castToNumber() {
    // Dirty hack to get null value instead of empty string
    if (this.formControl.value === '') {
      this.formControl.patchValue(null);
    }
  }
}
