import { DataTypeKeys } from '@etoh/database/core';

export type pluralType = 'singular' | 'plural';

const EntitiesWording: Record<DataTypeKeys, string> = {
  addresses: 'Address',
  companies: 'Company',
  reminders: 'Reminder',
  users: 'User',
  contacts: 'Contact',
  items: 'Item',
  buySpecs: 'Buy Spec',
  offices: 'Office',
  agreements: 'Agreement',
  agreementTerms: 'Agreement Term',
  history: 'History',
  shipmentFulfillments: 'Item Fulfillment',
  matchingItemsBuySpecs: 'Matching Items Buy Spec',
  sampleShipmentRequests: 'Sample Shipment Request',
  sampleShipments: 'Sample Shipment',
  invoices: 'Invoice',
  companyAddress: 'Company Address',
  samples: 'Sample',
  logs: 'Log',
  trackings: 'Tracking',
  contracts: 'Contract',
  validationsContracts: 'Validations Contract',
  usersBonus: 'User Bonus',
  usersCosts: 'User Cost',
  currenciesConversions: 'Currency Conversion',
  notifications: 'Notification',
  appellations: 'Appellation',
  varietals: 'Varietal',
};

const EntitiesWordingPlural: Record<DataTypeKeys, string> = {
  addresses: 'Addresses',
  companies: 'Companies',
  reminders: 'Reminders',
  users: 'Users',
  contacts: 'Contacts',
  items: 'Items',
  buySpecs: 'Buy Specs',
  offices: 'Offices',
  agreements: 'Agreements',
  agreementTerms: 'Agreement Terms',
  history: 'Histories',
  shipmentFulfillments: 'Item Fulfillments',
  matchingItemsBuySpecs: 'Matching Items Buy Specs',
  sampleShipmentRequests: 'Sample Shipment Requests',
  sampleShipments: 'Sample Shipments',
  invoices: 'Invoices',
  companyAddress: 'Company Addresses',
  samples: 'Samples',
  logs: 'Logs',
  trackings: 'Trackings',
  contracts: 'Contracts',
  validationsContracts: 'Validations Contracts',
  usersCosts: 'User Costs',
  usersBonus: 'User Bonuses',
  currenciesConversions: 'Currencies Conversions',
  notifications: 'Notifications',
  appellations: 'Appellations',
  varietals: 'Varietals',
};

export function getEntitiesWording(
  entity: DataTypeKeys,
  plural = false
): string {
  if (plural) {
    return EntitiesWordingPlural[entity];
  }

  return EntitiesWording[entity];
}
