import { SelectOptions } from '@etoh/database/core';
import { values } from 'lodash';

export const CountryDictionary = {
  AFG: {
    value: 'AFG',
    text: 'Afghanistan',
    flag: 'afghanistan.svg',
    countryCode: 'AF',
  },
  ALB: {
    value: 'ALB',
    text: 'Albania',
    flag: 'albania.svg',
    countryCode: 'AL',
  },
  DZA: {
    value: 'DZA',
    text: 'Algeria',
    flag: 'algeria.svg',
    countryCode: 'DZ',
  },
  ASM: {
    value: 'ASM',
    text: 'American Samoa',
    flag: 'american-samoa.svg',
    countryCode: 'AS',
  },
  AND: {
    value: 'AND',
    text: 'Andorra',
    flag: 'andorra.svg',
    countryCode: 'AD',
  },
  AGO: { value: 'AGO', text: 'Angola', flag: 'angola.svg', countryCode: 'AO' },
  AIA: {
    value: 'AIA',
    text: 'Anguilla',
    flag: 'anguilla.svg',
    countryCode: 'AI',
  },
  ATG: {
    value: 'ATG',
    text: 'Antigua and Barbuda',
    flag: 'antigua-and-barbuda.svg',
    countryCode: 'AG',
  },
  ARG: {
    value: 'ARG',
    text: 'Argentina',
    flag: 'argentina.svg',
    countryCode: 'AR',
  },
  ARM: {
    value: 'ARM',
    text: 'Armenia',
    flag: 'armenia.svg',
    countryCode: 'AM',
  },
  ABW: { value: 'ABW', text: 'Aruba', flag: 'aruba.svg', countryCode: 'AW' },
  AUS: {
    value: 'AUS',
    text: 'Australia',
    flag: 'australia.svg',
    countryCode: 'AU',
  },
  AUT: {
    value: 'AUT',
    text: 'Austria',
    flag: 'austria.svg',
    countryCode: 'AT',
  },
  AZE: {
    value: 'AZE',
    text: 'Azerbaijan',
    flag: 'azerbaijan.svg',
    countryCode: 'AZ',
  },
  BHS: {
    value: 'BHS',
    text: 'Bahamas',
    flag: 'bahamas.svg',
    countryCode: 'BS',
  },
  BHR: {
    value: 'BHR',
    text: 'Bahrain',
    flag: 'bahrain.svg',
    countryCode: 'BH',
  },
  BGD: {
    value: 'BGD',
    text: 'Bangladesh',
    flag: 'bangladesh.svg',
    countryCode: 'BD',
  },
  BRB: {
    value: 'BRB',
    text: 'Barbados',
    flag: 'barbados.svg',
    countryCode: 'BB',
  },
  BLR: {
    value: 'BLR',
    text: 'Belarus',
    flag: 'belarus.svg',
    countryCode: 'BY',
  },
  BEL: {
    value: 'BEL',
    text: 'Belgium',
    flag: 'belgium.svg',
    countryCode: 'BE',
  },
  BLZ: { value: 'BLZ', text: 'Belize', flag: 'belize.svg', countryCode: 'BZ' },
  BEN: { value: 'BEN', text: 'Benin', flag: 'benin.svg', countryCode: 'BJ' },
  BMU: {
    value: 'BMU',
    text: 'Bermuda',
    flag: 'bermuda.svg',
    countryCode: 'BM',
  },
  BTN: { value: 'BTN', text: 'Bhutan', flag: 'bhutan.svg', countryCode: 'BT' },
  BOL: {
    value: 'BOL',
    text: 'Bolivia (Plurinational State of)',
    flag: 'bolivia.svg',
    countryCode: 'BO',
  },
  BIH: {
    value: 'BIH',
    text: 'Bosnia and Herzegovina',
    flag: 'bosnia-and-herzegovina.svg',
    countryCode: 'BA',
  },
  BWA: {
    value: 'BWA',
    text: 'Botswana',
    flag: 'botswana.svg',
    countryCode: 'BW',
  },
  BRA: { value: 'BRA', text: 'Brazil', flag: 'brazil.svg', countryCode: 'BR' },
  IOT: {
    value: 'IOT',
    text: 'British Indian Ocean Territory',
    flag: 'british-indian-ocean-territory.svg',
    countryCode: 'VG',
  },
  BRN: {
    value: 'BRN',
    text: 'Brunei Darussalam',
    flag: 'brunei.svg',
    countryCode: 'BN',
  },
  BGR: {
    value: 'BGR',
    text: 'Bulgaria',
    flag: 'bulgaria.svg',
    countryCode: 'BG',
  },
  BFA: {
    value: 'BFA',
    text: 'Burkina Faso',
    flag: 'burkina-faso.svg',
    countryCode: 'BF',
  },
  BDI: {
    value: 'BDI',
    text: 'Burundi',
    flag: 'burundi.svg',
    countryCode: 'BI',
  },
  KHM: {
    value: 'KHM',
    text: 'Cambodia',
    flag: 'cambodia.svg',
    countryCode: 'KH',
  },
  CMR: {
    value: 'CMR',
    text: 'Cameroon',
    flag: 'cameroon.svg',
    countryCode: 'CM',
  },
  CAN: { value: 'CAN', text: 'Canada', flag: 'canada.svg', countryCode: 'CA' },
  CYM: {
    value: 'CYM',
    text: 'Cayman Islands',
    flag: 'cayman-islands.svg',
    countryCode: 'KY',
  },
  CAF: {
    value: 'CAF',
    text: 'Central African Republic',
    flag: 'central-african-republic.svg',
    countryCode: 'CF',
  },
  TCD: { value: 'TCD', text: 'Chad', flag: 'chad.svg', countryCode: 'TD' },
  CHL: { value: 'CHL', text: 'Chile', flag: 'chile.svg', countryCode: 'CL' },
  CHN: { value: 'CHN', text: 'China', flag: 'china.svg', countryCode: 'CN' },
  CXR: {
    value: 'CXR',
    text: 'Christmas Island',
    flag: 'christmas-island.svg',
    countryCode: 'CX',
  },
  COL: {
    value: 'COL',
    text: 'Colombia',
    flag: 'colombia.svg',
    countryCode: 'CO',
  },
  COM: {
    value: 'COM',
    text: 'Comoros',
    flag: 'comoros.svg',
    countryCode: 'KM',
  },
  COK: {
    value: 'COK',
    text: 'Cook Islands',
    flag: 'cook-islands.svg',
    countryCode: 'CK',
  },
  CRI: {
    value: 'CRI',
    text: 'Costa Rica',
    flag: 'costa-rica.svg',
    countryCode: 'CR',
  },
  HRV: {
    value: 'HRV',
    text: 'Croatia',
    flag: 'croatia.svg',
    countryCode: 'HR',
  },
  CUB: { value: 'CUB', text: 'Cuba', flag: 'cuba.svg', countryCode: 'CU' },
  CYP: { value: 'CYP', text: 'Cyprus', flag: 'cyprus.svg', countryCode: 'CY' },
  CZE: {
    value: 'CZE',
    text: 'Czechia',
    flag: 'czech-republic.svg',
    countryCode: 'CZ',
  },
  COD: {
    value: 'COD',
    text: 'Congo, Democratic Republic of the',
    flag: 'democratic-republic-of-congo.svg',
    countryCode: 'CD',
  },
  DNK: {
    value: 'DNK',
    text: 'Denmark',
    flag: 'denmark.svg',
    countryCode: 'DK',
  },
  DJI: {
    value: 'DJI',
    text: 'Djibouti',
    flag: 'djibouti.svg',
    countryCode: 'DJ',
  },
  DMA: {
    value: 'DMA',
    text: 'Dominica',
    flag: 'dominica.svg',
    countryCode: 'DM',
  },
  DOM: {
    value: 'DOM',
    text: 'Dominican Republic',
    flag: 'dominican-republic.svg',
    countryCode: 'DO',
  },
  ECU: {
    value: 'ECU',
    text: 'Ecuador',
    flag: 'ecuador.svg',
    countryCode: 'EC',
  },
  EGY: { value: 'EGY', text: 'Egypt', flag: 'egypt.svg', countryCode: 'EG' },
  GNQ: {
    value: 'GNQ',
    text: 'Equatorial Guinea',
    flag: 'equatorial-guinea.svg',
    countryCode: 'GQ',
  },
  ERI: {
    value: 'ERI',
    text: 'Eritrea',
    flag: 'eritrea.svg',
    countryCode: 'ER',
  },
  EST: {
    value: 'EST',
    text: 'Estonia',
    flag: 'estonia.svg',
    countryCode: 'EE',
  },
  ETH: {
    value: 'ETH',
    text: 'Ethiopia',
    flag: 'ethiopia.svg',
    countryCode: 'ET',
  },
  FRO: {
    value: 'FRO',
    text: 'Faroe Islands',
    flag: 'faroe-islands.svg',
    countryCode: 'FO',
  },
  FJI: { value: 'FJI', text: 'Fiji', flag: 'fiji.svg', countryCode: 'FJ' },
  FIN: {
    value: 'FIN',
    text: 'Finland',
    flag: 'finland.svg',
    countryCode: 'FI',
  },
  FRA: { value: 'FRA', text: 'France', flag: 'france.svg', countryCode: 'FR' },
  PYF: {
    value: 'PYF',
    text: 'French Polynesia',
    flag: 'french-polynesia.svg',
    countryCode: 'PF',
  },
  GAB: { value: 'GAB', text: 'Gabon', flag: 'gabon.svg', countryCode: 'GA' },
  GMB: { value: 'GMB', text: 'Gambia', flag: 'gambia.svg', countryCode: 'GM' },
  GEO: {
    value: 'GEO',
    text: 'Georgia',
    flag: 'georgia.svg',
    countryCode: 'GE',
  },
  DEU: {
    value: 'DEU',
    text: 'Germany',
    flag: 'germany.svg',
    countryCode: 'DE',
  },
  GHA: { value: 'GHA', text: 'Ghana', flag: 'ghana.svg', countryCode: 'GH' },
  GIB: {
    value: 'GIB',
    text: 'Gibraltar',
    flag: 'gibraltar.svg',
    countryCode: 'GI',
  },
  GRC: { value: 'GRC', text: 'Greece', flag: 'greece.svg', countryCode: 'GR' },
  GRL: {
    value: 'GRL',
    text: 'Greenland',
    flag: 'greenland.svg',
    countryCode: 'GL',
  },
  GRD: {
    value: 'GRD',
    text: 'Grenada',
    flag: 'grenada.svg',
    countryCode: 'GD',
  },
  GUM: { value: 'GUM', text: 'Guam', flag: 'guam.svg', countryCode: 'GU' },
  GTM: {
    value: 'GTM',
    text: 'Guatemala',
    flag: 'guatemala.svg',
    countryCode: 'GT',
  },
  GGY: {
    value: 'GGY',
    text: 'Guernsey',
    flag: 'guernsey.svg',
    countryCode: 'GG',
  },
  GIN: { value: 'GIN', text: 'Guinea', flag: 'guinea.svg', countryCode: 'GN' },
  GNB: {
    value: 'GNB',
    text: 'Guinea-Bissau',
    flag: 'guinea-bissau.svg',
    countryCode: 'GW',
  },
  GUY: { value: 'GUY', text: 'Guyana', flag: 'guyana.svg', countryCode: 'GY' },
  HTI: { value: 'HTI', text: 'Haiti', flag: 'haiti.svg', countryCode: 'HT' },
  HND: {
    value: 'HND',
    text: 'Honduras',
    flag: 'honduras.svg',
    countryCode: 'HN',
  },
  HKG: {
    value: 'HKG',
    text: 'Hong Kong',
    flag: 'hong-kong.svg',
    countryCode: 'HK',
  },
  HUN: {
    value: 'HUN',
    text: 'Hungary',
    flag: 'hungary.svg',
    countryCode: 'HU',
  },
  ISL: {
    value: 'ISL',
    text: 'Iceland',
    flag: 'iceland.svg',
    countryCode: 'IS',
  },
  IND: { value: 'IND', text: 'India', flag: 'india.svg', countryCode: 'IN' },
  IDN: {
    value: 'IDN',
    text: 'Indonesia',
    flag: 'indonesia.svg',
    countryCode: 'ID',
  },
  IRQ: { value: 'IRQ', text: 'Iraq', flag: 'iraq.svg', countryCode: 'IQ' },
  IRL: {
    value: 'IRL',
    text: 'Ireland',
    flag: 'ireland.svg',
    countryCode: 'IE',
  },
  IMN: {
    value: 'IMN',
    text: 'Isle of Man',
    flag: 'isle-of-man.svg',
    countryCode: 'IM',
  },
  ISR: { value: 'ISR', text: 'Israel', flag: 'israel.svg', countryCode: 'IL' },
  ITA: { value: 'ITA', text: 'Italy', flag: 'italy.svg', countryCode: 'IT' },
  JAM: {
    value: 'JAM',
    text: 'Jamaica',
    flag: 'jamaica.svg',
    countryCode: 'JM',
  },
  JPN: { value: 'JPN', text: 'Japan', flag: 'japan.svg', countryCode: 'JP' },
  JEY: { value: 'JEY', text: 'Jersey', flag: 'jersey.svg', countryCode: 'JE' },
  JOR: { value: 'JOR', text: 'Jordan', flag: 'jordan.svg', countryCode: 'JO' },
  KAZ: {
    value: 'KAZ',
    text: 'Kazakhstan',
    flag: 'kazakhstan.svg',
    countryCode: 'KZ',
  },
  KEN: { value: 'KEN', text: 'Kenya', flag: 'kenya.svg', countryCode: 'KE' },
  KIR: {
    value: 'KIR',
    text: 'Kiribati',
    flag: 'kiribati.svg',
    countryCode: 'KI',
  },
  KWT: { value: 'KWT', text: 'Kuwait', flag: 'kuwait.svg', countryCode: 'KW' },
  KGZ: {
    value: 'KGZ',
    text: 'Kyrgyzstan',
    flag: 'kyrgyzstan.svg',
    countryCode: 'KG',
  },
  LVA: { value: 'LVA', text: 'Latvia', flag: 'latvia.svg', countryCode: 'LV' },
  LBN: {
    value: 'LBN',
    text: 'Lebanon',
    flag: 'lebanon.svg',
    countryCode: 'LB',
  },
  LSO: {
    value: 'LSO',
    text: 'Lesotho',
    flag: 'lesotho.svg',
    countryCode: 'LS',
  },
  LBR: {
    value: 'LBR',
    text: 'Liberia',
    flag: 'liberia.svg',
    countryCode: 'LR',
  },
  LBY: { value: 'LBY', text: 'Libya', flag: 'libya.svg', countryCode: 'LY' },
  LIE: {
    value: 'LIE',
    text: 'Liechtenstein',
    flag: 'liechtenstein.svg',
    countryCode: 'LI',
  },
  LTU: {
    value: 'LTU',
    text: 'Lithuania',
    flag: 'lithuania.svg',
    countryCode: 'LT',
  },
  LUX: {
    value: 'LUX',
    text: 'Luxembourg',
    flag: 'luxembourg.svg',
    countryCode: 'LU',
  },
  MAC: { value: 'MAC', text: 'Macao', flag: 'macao.svg', countryCode: 'MO' },
  MDG: {
    value: 'MDG',
    text: 'Madagascar',
    flag: 'madagascar.svg',
    countryCode: 'MG',
  },
  MWI: { value: 'MWI', text: 'Malawi', flag: 'malawi.svg', countryCode: 'MW' },
  MYS: {
    value: 'MYS',
    text: 'Malaysia',
    flag: 'malaysia.svg',
    countryCode: 'MY',
  },
  MDV: {
    value: 'MDV',
    text: 'Maldives',
    flag: 'maldives.svg',
    countryCode: 'MV',
  },
  MLI: { value: 'MLI', text: 'Mali', flag: 'mali.svg', countryCode: 'ML' },
  MLT: { value: 'MLT', text: 'Malta', flag: 'malta.svg', countryCode: 'MT' },
  MTQ: {
    value: 'MTQ',
    text: 'Martinique',
    flag: 'martinique.svg',
    countryCode: 'MQ',
  },
  MRT: {
    value: 'MRT',
    text: 'Mauritania',
    flag: 'mauritania.svg',
    countryCode: 'MR',
  },
  MUS: {
    value: 'MUS',
    text: 'Mauritius',
    flag: 'mauritius.svg',
    countryCode: 'MU',
  },
  MEX: { value: 'MEX', text: 'Mexico', flag: 'mexico.svg', countryCode: 'MX' },
  MCO: { value: 'MCO', text: 'Monaco', flag: 'monaco.svg', countryCode: 'MC' },
  MNG: {
    value: 'MNG',
    text: 'Mongolia',
    flag: 'mongolia.svg',
    countryCode: 'MN',
  },
  MNE: {
    value: 'MNE',
    text: 'Montenegro',
    flag: 'montenegro.svg',
    countryCode: 'ME',
  },
  MSR: {
    value: 'MSR',
    text: 'Montserrat',
    flag: 'montserrat.svg',
    countryCode: 'MS',
  },
  MAR: {
    value: 'MAR',
    text: 'Morocco',
    flag: 'morocco.svg',
    countryCode: 'MA',
  },
  MOZ: {
    value: 'MOZ',
    text: 'Mozambique',
    flag: 'mozambique.svg',
    countryCode: 'MZ',
  },
  MMR: {
    value: 'MMR',
    text: 'Myanmar',
    flag: 'myanmar.svg',
    countryCode: 'MM',
  },
  NAM: {
    value: 'NAM',
    text: 'Namibia',
    flag: 'namibia.svg',
    countryCode: 'NA',
  },
  NRU: { value: 'NRU', text: 'Nauru', flag: 'nauru.svg', countryCode: 'NR' },
  NPL: { value: 'NPL', text: 'Nepal', flag: 'nepal.svg', countryCode: 'NP' },
  NLD: {
    value: 'NLD',
    text: 'Netherlands',
    flag: 'netherlands.svg',
    countryCode: 'NL',
  },
  NZL: {
    value: 'NZL',
    text: 'New Zealand',
    flag: 'new-zealand.svg',
    countryCode: 'NZ',
  },
  NIC: {
    value: 'NIC',
    text: 'Nicaragua',
    flag: 'nicaragua.svg',
    countryCode: 'NI',
  },
  NER: { value: 'NER', text: 'Niger', flag: 'niger.svg', countryCode: 'NI' },
  NGA: {
    value: 'NGA',
    text: 'Nigeria',
    flag: 'nigeria.svg',
    countryCode: 'NE',
  },
  NIU: { value: 'NIU', text: 'Niue', flag: 'niue.svg', countryCode: 'NG' },
  NFK: {
    value: 'NFK',
    text: 'Norfolk Island',
    flag: 'norfolk-island.svg',
    countryCode: 'NF',
  },
  NOR: { value: 'NOR', text: 'Norway', flag: 'norway.svg', countryCode: 'NO' },
  OMN: { value: 'OMN', text: 'Oman', flag: 'oman.svg', countryCode: 'OM' },
  PAK: {
    value: 'PAK',
    text: 'Pakistan',
    flag: 'pakistan.svg',
    countryCode: 'PK',
  },
  PLW: { value: 'PLW', text: 'Palau', flag: 'palau.svg', countryCode: 'PW' },
  PAN: { value: 'PAN', text: 'Panama', flag: 'panama.svg', countryCode: 'PA' },
  PNG: {
    value: 'PNG',
    text: 'Papua New Guinea',
    flag: 'papua-new-guinea.svg',
    countryCode: 'PG',
  },
  PRY: {
    value: 'PRY',
    text: 'Paraguay',
    flag: 'paraguay.svg',
    countryCode: 'PY',
  },
  PER: { value: 'PER', text: 'Peru', flag: 'peru.svg', countryCode: 'PE' },
  PHL: {
    value: 'PHL',
    text: 'Philippines',
    flag: 'philippines.svg',
    countryCode: 'PH',
  },
  PRT: {
    value: 'PRT',
    text: 'Portugal',
    flag: 'portugal.svg',
    countryCode: 'PT',
  },
  PRI: {
    value: 'PRI',
    text: 'Puerto Rico',
    flag: 'puerto-rico.svg',
    countryCode: 'PR',
  },
  QAT: { value: 'QAT', text: 'Qatar', flag: 'qatar.svg', countryCode: 'QA' },
  ROU: {
    value: 'ROU',
    text: 'Romania',
    flag: 'romania.svg',
    countryCode: 'RO',
  },
  RWA: { value: 'RWA', text: 'Rwanda', flag: 'rwanda.svg', countryCode: 'RW' },
  KNA: {
    value: 'KNA',
    text: 'Saint Kitts and Nevis',
    flag: 'saint-kitts-and-nevis.svg',
    countryCode: 'KN',
  },
  WSM: { value: 'WSM', text: 'Samoa', flag: 'samoa.svg', countryCode: 'WS' },
  SMR: {
    value: 'SMR',
    text: 'San Marino',
    flag: 'san-marino.svg',
    countryCode: 'SM',
  },
  STP: {
    value: 'STP',
    text: 'Sao Tome and Principe',
    flag: 'sao-tome-and-principe.svg',
    countryCode: 'ST',
  },
  SAU: {
    value: 'SAU',
    text: 'Saudi Arabia',
    flag: 'saudi-arabia.svg',
    countryCode: 'SA',
  },
  SEN: {
    value: 'SEN',
    text: 'Senegal',
    flag: 'senegal.svg',
    countryCode: 'SN',
  },
  SRB: { value: 'SRB', text: 'Serbia', flag: 'serbia.svg', countryCode: 'RS' },
  SYC: {
    value: 'SYC',
    text: 'Seychelles',
    flag: 'seychelles.svg',
    countryCode: 'SC',
  },
  SLE: {
    value: 'SLE',
    text: 'Sierra Leone',
    flag: 'sierra-leone.svg',
    countryCode: 'SL',
  },
  SGP: {
    value: 'SGP',
    text: 'Singapore',
    flag: 'singapore.svg',
    countryCode: 'SG',
  },
  SVK: {
    value: 'SVK',
    text: 'Slovakia',
    flag: 'slovakia.svg',
    countryCode: 'SK',
  },
  SVN: {
    value: 'SVN',
    text: 'Slovenia',
    flag: 'slovenia.svg',
    countryCode: 'SI',
  },
  SLB: {
    value: 'SLB',
    text: 'Solomon Islands',
    flag: 'solomon-islands.svg',
    countryCode: 'SB',
  },
  SOM: {
    value: 'SOM',
    text: 'Somalia',
    flag: 'somalia.svg',
    countryCode: 'SO',
  },
  ZAF: {
    value: 'ZAF',
    text: 'South Africa',
    flag: 'south-africa.svg',
    countryCode: 'ZA',
  },
  SSD: {
    value: 'SSD',
    text: 'South Sudan',
    flag: 'south-sudan.svg',
    countryCode: 'SS',
  },
  ESP: { value: 'ESP', text: 'Spain', flag: 'spain.svg', countryCode: 'ES' },
  LKA: {
    value: 'LKA',
    text: 'Sri Lanka',
    flag: 'sri-lanka.svg',
    countryCode: 'LK',
  },
  SDN: { value: 'SDN', text: 'Sudan', flag: 'sudan.svg', countryCode: 'SD' },
  SUR: {
    value: 'SUR',
    text: 'Suriname',
    flag: 'suriname.svg',
    countryCode: 'SR',
  },
  SWE: { value: 'SWE', text: 'Sweden', flag: 'sweden.svg', countryCode: 'SE' },
  CHE: {
    value: 'CHE',
    text: 'Switzerland',
    flag: 'switzerland.svg',
    countryCode: 'CH',
  },
  TJK: {
    value: 'TJK',
    text: 'Tajikistan',
    flag: 'tajikistan.svg',
    countryCode: 'TJ',
  },
  THA: {
    value: 'THA',
    text: 'Thailand',
    flag: 'thailand.svg',
    countryCode: 'TH',
  },
  TGO: { value: 'TGO', text: 'Togo', flag: 'togo.svg', countryCode: 'TG' },
  TKL: {
    value: 'TKL',
    text: 'Tokelau',
    flag: 'tokelau.svg',
    countryCode: 'TK',
  },
  TON: { value: 'TON', text: 'Tonga', flag: 'tonga.svg', countryCode: 'TO' },
  TTO: {
    value: 'TTO',
    text: 'Trinidad and Tobago',
    flag: 'trinidad-and-tobago.svg',
    countryCode: 'TT',
  },
  TUN: {
    value: 'TUN',
    text: 'Tunisia',
    flag: 'tunisia.svg',
    countryCode: 'TN',
  },
  TUR: { value: 'TUR', text: 'Turkey', flag: 'turkey.svg', countryCode: 'TR' },
  TKM: {
    value: 'TKM',
    text: 'Turkmenistan',
    flag: 'turkmenistan.svg',
    countryCode: 'TM',
  },
  TUV: { value: 'TUV', text: 'Tuvalu', flag: 'tuvalu.svg', countryCode: 'TV' },
  UGA: { value: 'UGA', text: 'Uganda', flag: 'uganda.svg', countryCode: 'UG' },
  UKR: {
    value: 'UKR',
    text: 'Ukraine',
    flag: 'ukraine.svg',
    countryCode: 'UA',
  },
  ARE: {
    value: 'ARE',
    text: 'United Arab Emirates',
    flag: 'united-arab-emirates.svg',
    countryCode: 'AE',
  },
  GBR: {
    value: 'GBR',
    text: 'United Kingdom',
    flag: 'united-kingdom.svg',
    countryCode: 'GB',
  },
  USA: {
    value: 'USA',
    text: 'United States',
    flag: 'usa.svg',
    countryCode: 'US',
  },
  URY: {
    value: 'URY',
    text: 'Uruguay',
    flag: 'uruguay.svg',
    countryCode: 'UY',
  },
  VUT: {
    value: 'VUT',
    text: 'Vanuatu',
    flag: 'vanuatu.svg',
    countryCode: 'VU',
  },
  VEN: {
    value: 'VEN',
    text: 'Venezuela',
    flag: 'venezuela.svg',
    countryCode: 'VE',
  },
  VNM: {
    value: 'VNM',
    text: 'Vietnam',
    flag: 'vietnam.svg',
    countryCode: 'VN',
  },
  ESH: {
    value: 'ESH',
    text: 'Western Sahara',
    flag: 'western-sahara.svg',
    countryCode: 'EH',
  },
  YEM: { value: 'YEM', text: 'Yemen', flag: 'yemen.svg', countryCode: 'YE' },
  ZMB: { value: 'ZMB', text: 'Zambia', flag: 'zambia.svg', countryCode: 'ZM' },
  ZWE: {
    value: 'ZWE',
    text: 'Zimbabwe',
    flag: 'zimbabwe.svg',
    countryCode: 'ZW',
  },
  MDA: {
    value: 'MDA',
    text: 'Moldova',
    flag: 'moldova.svg',
    countryCode: 'MD',
  },
  POL: {
    value: 'POL',
    text: 'Poland',
    flag: 'republic-of-poland.svg',
    countryCode: 'PL',
  },
  RUS: { value: 'RUS', text: 'Russia', flag: 'russia.svg', countryCode: 'RU' },

  PRK: {
    value: 'PRK',
    text: 'North Korea',
    flag: 'north-korea.svg',
    countryCode: 'KP',
  },
  KOR: {
    value: 'KOR',
    text: 'South Korea',
    flag: 'south-korea.svg',
    countryCode: 'KR',
  },
  TWM: {
    value: 'TWN',
    text: 'Taiwan',
    flag: 'taiwan.svg',
    countryCode: 'TW',
  },
};

export type CountryCode = keyof typeof CountryDictionary;

export const StateDictionary = {
  AUS: [
    { value: 'Western Australia', text: 'Western Australia' },
    { value: 'Victoria', text: 'Victoria' },
    { value: 'South Australia', text: 'South Australia' },
    { value: 'New South Wales', text: 'New South Wales' },
    {
      value: 'Australian Capital Territory',
      text: 'Australian Capital Territory',
    },
    { value: 'Queensland', text: 'Queensland' },
    { value: 'Northern Territory', text: 'Northern Territory' },
    { value: 'Tasmania', text: 'Tasmania' },
  ],
  CAN: [{ value: 'Ontario', text: 'Ontario' }],
  USA: [
    { value: 'Alabama', text: 'Alabama' },
    { value: 'Alaska', text: 'Alaska' },
    { value: 'Arizona', text: 'Arizona' },
    { value: 'Arkansas', text: 'Arkansas' },
    { value: 'California', text: 'California' },
    { value: 'Colorado', text: 'Colorado' },
    { value: 'Connecticut', text: 'Connecticut' },
    { value: 'Delaware', text: 'Delaware' },
    { value: 'Florida', text: 'Florida' },
    { value: 'Georgia', text: 'Georgia' },
    { value: 'Hawaii', text: 'Hawaii' },
    { value: 'Idaho', text: 'Idaho' },
    { value: 'Illinois', text: 'Illinois' },
    { value: 'Indiana', text: 'Indiana' },
    { value: 'Iowa', text: 'Iowa' },
    { value: 'Kansas', text: 'Kansas' },
    { value: 'Kentucky', text: 'Kentucky' },
    { value: 'Louisiana', text: 'Louisiana' },
    { value: 'Maine', text: 'Maine' },
    { value: 'Maryland', text: 'Maryland' },
    { value: 'Massachusetts', text: 'Massachusetts' },
    { value: 'Michigan', text: 'Michigan' },
    { value: 'Minnesota', text: 'Minnesota' },
    { value: 'Mississippi', text: 'Mississippi' },
    { value: 'Missouri', text: 'Missouri' },
    { value: 'Montana', text: 'Montana' },
    { value: 'Nebraska', text: 'Nebraska' },
    { value: 'Nevada', text: 'Nevada' },
    { value: 'New Hampshire', text: 'New Hampshire' },
    { value: 'New Jersey', text: 'New Jersey' },
    { value: 'New Mexico', text: 'New Mexico' },
    { value: 'New York', text: 'New York' },
    { value: 'North Carolina', text: 'North Carolina' },
    { value: 'North Dakota', text: 'North Dakota' },
    { value: 'Ohio', text: 'Ohio' },
    { value: 'Oklahoma', text: 'Oklahoma' },
    { value: 'Oregon', text: 'Oregon' },
    { value: 'Pennsylvania', text: 'Pennsylvania' },
    { value: 'Rhode Island', text: 'Rhode Island' },
    { value: 'South Carolina', text: 'South Carolina' },
    { value: 'South Dakota', text: 'South Dakota' },
    { value: 'Tennessee', text: 'Tennessee' },
    { value: 'Texas', text: 'Texas' },
    { value: 'Utah', text: 'Utah' },
    { value: 'Vermont', text: 'Vermont' },
    { value: 'Virginia', text: 'Virginia' },
    { value: 'Washington', text: 'Washington' },
    { value: 'West Virginia', text: 'West Virginia' },
    { value: 'Wisconsin', text: 'Wisconsin' },
    { value: 'Wyoming', text: 'Wyoming' },
  ],
  DEU: [
    { value: 'Baden-Württemberg', text: 'Baden-Württemberg' },
    { value: 'Bayern', text: 'Bayern' },
    { value: 'Berlin', text: 'Berlin' },
    { value: 'Brandenburg', text: 'Brandenburg' },
    { value: 'Bremen', text: 'Bremen' },
    { value: 'Hamburg', text: 'Hamburg' },
    { value: 'Hessen', text: 'Hessen' },
    { value: 'Mecklenburg-Vorpommern', text: 'Mecklenburg-Vorpommern' },
    { value: 'Niedersachsen', text: 'Niedersachsen' },
    { value: 'Nordrhein-Westfalen', text: 'Nordrhein-Westfalen' },
    { value: 'Rheinland-Pfalz', text: 'Rheinland-Pfalz' },
    { value: 'Saarland', text: 'Saarland' },
    { value: 'Sachsen', text: 'Sachsen' },
    { value: 'Sachsen-Anhalt', text: 'Sachsen-Anhalt' },
    { value: 'Schleswig-Holstein', text: 'Schleswig-Holstein' },
    { value: 'Thüringen', text: 'Thüringen' },
  ],
};

export const findCountryBy2LetterCode = (code: string) => {
  return values(CountryDictionary).find((c) => c.countryCode === code);
};

export const getCountryOptions = (): SelectOptions[] => {
  return values(CountryDictionary).map((country) => {
    return {
      value: country.value,
      label: country.text,
    };
  });
};

export const getStateOptions = (countryCode: string): SelectOptions[] => {
  return ((StateDictionary as any)[countryCode] || []).map((state: any) => {
    return {
      value: state.value,
      label: state.text,
    };
  });
};

export const getCountryCode = (countryName: string) => {
  return Object.values(CountryDictionary).find((c) => c.text === countryName)
    ?.countryCode;
};

export const countriesInUE: CountryCode[] = [
  'AUT',
  'BEL',
  'BGR',
  'HRV',
  'CYP',
  'CZE',
  'DNK',
  'EST',
  'FIN',
  'FRA',
  'DEU',
  'GRC',
  'HUN',
  'IRL',
  'ITA',
  'LVA',
  'LTU',
  'LUX',
  'MLT',
  'NLD',
  'POL',
  'PRT',
  'ROU',
  'SVK',
  'SVN',
  'ESP',
  'SWE',
];

export const isCountryInUE = (country: CountryCode): boolean => {
  return countriesInUE.includes(country);
};
