import { uomTypes } from './database';

export interface SelectOptions<T = any> {
  label: string;
  value: T;
  subLabel?: string;
  group?: string;
}

export const uomSelectOptions: SelectOptions<uomTypes>[] = [
  {
    label: 'hectoliters',
    value: 'hl',
  },
  {
    label: 'liters',
    value: 'l',
  },
  {
    label: 'US gal',
    value: 'US gal',
  },
  {
    label: 'Acres',
    value: 'Acres',
  },
  {
    label: 'Bags',
    value: 'Bags',
  },
  {
    label: 'Barrels',
    value: 'Barrels',
  },
  {
    label: 'Bottles',
    value: 'Bottles',
  },
  {
    label: 'Cans',
    value: 'Cans',
  },
  {
    label: 'Cases-12',
    value: 'Cases-12',
  },
  {
    label: 'Cases-6',
    value: 'Cases-6',
  },
  {
    label: 'Drums',
    value: 'Drums',
  },
  {
    label: 'Each',
    value: 'Each',
  },
  {
    label: 'kg',
    value: 'kg',
  },
  {
    label: 'Alc. l',
    value: 'Alc. l',
  },
  {
    label: 'lb',
    value: 'lb',
  },
  {
    label: 'Tonnes',
    value: 'Tonnes',
  },
  {
    label: 'Pail',
    value: 'Pail',
  },
  {
    label: 'Pallet',
    value: 'Pallet',
  },
  {
    label: 'Proof Gallons',
    value: 'Proof Gallons',
  },
  {
    label: 'Totes',
    value: 'Totes',
  },
  {
    label: 'hectares',
    value: 'hectares',
  },
  {
    label: 'Hectogrado',
    value: 'Hectogrado',
  },
  {
    label: 'US tons',
    value: 'US tons',
  },
];

export const commodityOptions = [
  {
    label: 'Bulk Wine',
    value: 'bulkWine',
  },
  {
    label: 'Grape Juice Concentrate',
    value: 'grapeJuiceConcentrate',
  },
  {
    label: 'Grape Juice',
    value: 'grapeJuice',
  },
  {
    label: 'Grape',
    value: 'grape',
  },
  {
    label: 'Bulk Spirits',
    value: 'bulkSpirits',
  },
  {
    label: 'Bottled Wine',
    value: 'bottledWine',
  },
  {
    label: 'Barrels',
    value: 'barrels',
  },
  {
    label: 'Other',
    value: 'other',
  },
  {
    label: 'Bottled Spirits',
    value: 'bottledSpirits',
  },
];

export const currencyOptions = [
  {
    value: 'EUR',
    label: 'EUR',
  },
  {
    value: 'USD',
    label: 'USD',
  },
  {
    value: 'AUD',
    label: 'AUD',
  },
  {
    value: 'GBP',
    label: 'GBP',
  },
  {
    value: 'CHF',
    label: 'CHF',
  },
  {
    value: 'CAD',
    label: 'CAD',
  },
  {
    value: 'CLP',
    label: 'CLP',
  },
  {
    value: 'ARS',
    label: 'ARS',
  },
  {
    value: 'NZD',
    label: 'NZD',
  },
  {
    value: 'SEK',
    label: 'SEK',
  },
  {
    value: 'NOK',
    label: 'NOK',
  },
  {
    value: 'RUB',
    label: 'RUB',
  },
  {
    value: 'ZAR',
    label: 'ZAR',
  },
  {
    value: 'JPY',
    label: 'JPY',
  },
  {
    value: 'CNY',
    label: 'CNY',
  },
  {
    value: 'BRL',
    label: 'BRL',
  },
].map((d) => ({
  ...d,
  label: `${d.label}`,
}));

export const incotermsOptions = [
  {
    value: 'CIP',
    label: 'CIP',
  },
  {
    value: 'CPT',
    label: 'CPT',
  },
  {
    value: 'CFR',
    label: 'CFR',
  },
  {
    value: 'CIF',
    label: 'CIF',
  },
  {
    value: 'DAP',
    label: 'DAP',
  },
  {
    value: 'DAT',
    label: 'DAT',
  },
  {
    value: 'DDP',
    label: 'DDP',
  },
  {
    value: 'EXW',
    label: 'EXW',
  },
  {
    value: 'FAS',
    label: 'FAS',
  },
  {
    value: 'FCA',
    label: 'FCA',
  },
  {
    value: 'FOB',
    label: 'FOB',
  },
];
