import { inject, Injectable } from '@angular/core';
import { Items, SelectOptions } from '@etoh/database/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { map, Observable, shareReplay } from 'rxjs';
import { getSelectOptions } from '../../dynamic-form/utilities';
import { StoreService } from '../store.service';
import { getItemsTooltip } from './items.pure';
import { AiWorkflowDrawerComponent } from './standalone/ai-workflow-drawer.component';
import { GeneratedItem } from './standalone/ai-workflow/item-workflow-add.interface';

@Injectable({
  providedIn: 'root',
})
export class ItemsService {
  private storeService = inject(StoreService);
  private drawerService = inject(NzDrawerService);

  getItemsForOptions$(): Observable<SelectOptions[]> {
    return this.storeService.store.items.data$.pipe(
      map((items) => this.getItemsForOptions(items))
    );
  }

  getItemsForOptions(items: Items[]): SelectOptions[] {
    return getSelectOptions(
      items,
      (item) => this.getItemLabel(item),
      'id',

      (item) => {
        return getItemsTooltip(item);
      }
    );
  }

  getItemLabel(item: Items): string {
    return `Item ${item.id}`;
  }

  getItemTooltipFromId(id: number): Observable<string> {
    return this.storeService
      .getObjectFromStoreById<Items>('items', id)
      .pipe(map(getItemsTooltip), shareReplay(1));
  }

  getEstimatedCommission(item: Items, commissionPercentage = 2): number | null {
    if (item.localPrice && item.quantity) {
      return item.localPrice * item.quantity * (commissionPercentage / 100);
    }
    return null;
  }

  openAiWorkflowDrawer(couldBeBulk = false) {
    const drawerRef = this.drawerService.create<
      AiWorkflowDrawerComponent,
      { couldBeBulk?: boolean },
      GeneratedItem[] | undefined
    >({
      nzTitle: 'Where magic happens',
      nzContent: AiWorkflowDrawerComponent,
      nzWidth: '500px',
      nzContentParams: {},
      nzData: {
        couldBeBulk,
      },
    });

    return drawerRef;
  }
}
