import { Injectable } from '@angular/core';
import { SelectOptions } from '@etoh/database/core';
import { sortBy } from 'lodash';
import { map, Observable } from 'rxjs';
import { StoreService } from '../store.service';

@Injectable({
  providedIn: 'root',
})
export class OfficesService {
  constructor(private storeService: StoreService) {}

  getOfficesForOptions$(): Observable<SelectOptions[]> {
    return this.storeService.store.offices.data$.pipe(
      map((offices) =>
        offices.map((office) => ({
          label: office.name,
          value: office.id,
        }))
      ),
      map((options) => {
        return sortBy(options, 'label');
      })
    );
  }
}
