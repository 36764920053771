import { Component, OnInit } from '@angular/core';
import { SelectOptions } from '@etoh/database/core';

import { FieldType } from '@ngx-formly/core';

interface CheckbxGroup {
  props: {
    disabled: boolean;
    options: SelectOptions[];
    onChange: (value: (string | number)[]) => void;
  };
}

@Component({
  selector: 'etoh-checkbox-group',
  template: `
    <nz-checkbox-wrapper style="width: 100%;" (nzOnChange)="onChange($event)">
      <div>
        @for (option of props.options; track option) {
        <div>
          <label nz-checkbox [nzValue]="option.value">
            {{ option.label }}
          </label>
        </div>
        }
      </div>
    </nz-checkbox-wrapper>
  `,
  styles: [],
})
export class CheckboxGroupComponent extends FieldType<any> implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {}

  onChange($event: (string | number)[]) {
    if (this.props.onChange) {
      this.props.onChange($event);
    }
  }
}
