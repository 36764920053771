import { Injectable } from '@angular/core';
import { Contacts } from '@etoh/database/angular';
import { sortBy } from 'lodash';
import { map, Observable, shareReplay } from 'rxjs';

import { SelectOptions } from '@etoh/database/core';
import { StoreService } from '../store.service';

@Injectable({
  providedIn: 'root',
})
export class ContactsService {
  constructor(private storeService: StoreService) {}

  getContactsForOptions$(
    companyId: number | null = null
  ): Observable<SelectOptions[]> {
    return this.storeService.store.contacts.data$.pipe(
      map((contacts) => {
        if (companyId) {
          contacts = contacts.filter(
            (contact) => contact.companyId === companyId
          );
        }

        return contacts.map((contact) => ({
          label: this.getLabelForContact(contact),
          value: contact.id,
        }));
      }),
      map((options) => {
        return sortBy(options, 'label');
      })
    );
  }

  getLabelForContact(user: Contacts): string {
    if (!user) {
      return '';
    }
    return `${user.firstname} ${user.lastname}`;
  }

  getTooltipFromId(id: number): Observable<string> {
    return this.storeService
      .getObjectFromStoreById<Contacts>('contacts', id)
      .pipe(map(this.getLabelForContact), shareReplay(1));
  }
}
